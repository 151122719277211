// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { Button, Form, Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Environment } from '../environment';
import '../assets/styles/service-issues.scss';
import { IDynamoAdvocateModel } from './interfaces/IDynamoAdvocateModel';
import { IClientProfileModel } from './interfaces/IClientProfileModel';
import { INewClientServiceIssueDefinition } from './INewClientServiceIssueDefinition';
import { Utils } from '../utils/utils';

export class ClientServiceIssuesButtonSection extends Component<{}> {
  props: {
    children?: any,
    key: number,
    tileData: any,
    user: any,
    profileData: IClientProfileModel
  };

  state: {
    title?: string;
    usagePercent?: number;
    menuItems?: Array<any>;
    modalShowing: boolean;
    message: string;
    category: string;
    clientList?: Array<any>;
    clientId: string;
    rmUserId: number;
    statusesList?: Array<any>;
    statusId: number;
    categoryId: number;
    propertyId: string;
    client?: string;
    feedbackModalShowing: boolean;
    createdServiceIssue?: any;
    advocatesData: Array<IDynamoAdvocateModel>;
    dataLoadingError: boolean;
  } = {
    'modalShowing': false,
    'message': '',
    'category': '',
    'clientId': '',
    'rmUserId': 0,
    'statusId': 0,
    'categoryId': 0,
    'propertyId': '',
    'feedbackModalShowing': false,
    'advocatesData': [],
    'dataLoadingError': false
  };

  constructor(props: any) {
    super(props);
    this.props = props;

    this.state.modalShowing = false;
    this.state.feedbackModalShowing = false;
    this.state.message = '';
    this.state.category = '';
  }

  async componentDidMount() {
    try {
      await Promise.all([await this.loadDefaultData()]).then(() => {
        const tileTitle = _.get(this.props.tileData, 'name');
        const tileUsage = _.get(this.props.tileData, 'usage');
        const tileMenu = _.get(this.props.tileData, 'menuItems');
        this.setState({
          'title': tileTitle, 'usagePercent': tileUsage, 'menuItems': tileMenu, 'dataLoadingError': false
        });
      });
    } catch (e) {
      console.log(e);
      this.setState({ 'dataLoadingError': true });
    }
  }

  handleShow = () => {
    this.setState({ 'modalShowing': true });
    // this.forceUpdate();
  };

  handleClose = () => {
    this.setState({ 'modalShowing': false });
    this.setState({ 'message': '' });
    this.setState({ 'category': '' });
    // this.forceUpdate();
  };

  private handleFeedbackModalClose = () => {
    this.setState({ 'feedbackModalShowing': false });
  };

  validateForm() {
    const { message, category } = this.state;
    let isValidValue = true;

    if (message.length === 0 || message.length > 350) {
      isValidValue = false;
    }

    if (category.length === 0) {
      isValidValue = false;
    }

    return isValidValue;
  }

  loadDefaultData = async () => {
    try {
      await fetch(`${Environment.get('SPRING_INTEGRATION_HUB_API_ROOT')}rm/clients/list`, {})
        .then((r) => r.json())
        // eslint-disable-next-line no-unused-vars
        .then((data) => {
          // getting the Anequim client UUID
          const anequimClient = _.find(data, { 'name': 'Anequim' });

          const anequimId = _.get(anequimClient, 'id');

          this.setState({ 'clientId': anequimId });
          this.loadUnassignedStatus(anequimId);
          this.loadUnassignedCategory(anequimId);
        });
    } catch (e) {
      console.log(e);
      this.setState({ 'dataLoadingError': true });
    }
  };

  loadUnassignedStatus(clientId: string) {
    if (clientId !== null) {
      try {
        const data = [
          {
            'ServiceManagerStatusID': 1,
            'Name': '<Unassigned>',
            'Description': 'Status has not been assigned',
            'SortOrder': 1,
            'IsActive': true
          },
          {
            'ServiceManagerStatusID': 9,
            'Name': 'System Enhancement',
            'Description': '',
            'SortOrder': 9,
            'IsActive': true
          }
        ];
        const unassignedData = _.find(data, { 'Name': '<Unassigned>' });
        const unassignedID = _.get(unassignedData, 'ServiceManagerStatusID');
        this.setState({ 'statusId': unassignedID });
      } catch (e) {
        console.log(e);
      }
    }
  }

  loadUnassignedCategory(clientId: string) {
    if (clientId !== null) {
      try {
        const data = [
          {
            'ServiceManagerCategoryID': 1,
            'Name': '<Unassigned>',
            'Description': 'Category has not been assigned',
            'SortOrder': 1,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 9,
            'Name': 'System Enhancements',
            'Description': '',
            'SortOrder': 9,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 10,
            'Name': 'REVIEWS',
            'Description': 'Raises, violations, benefits',
            'SortOrder': 10,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 12,
            'Name': 'Admin',
            'Description': 'Payroll, Payables, Receivables',
            'SortOrder': 12,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 13,
            'Name': 'Quality',
            'Description': '',
            'SortOrder': 13,
            'IsActive': true
          }
        ];
        const unassignedData = _.find(data, { 'Name': '<Unassigned>' });
        const unassignedID = _.get(unassignedData, 'ServiceManagerCategoryID');
        this.setState({ 'categoryId': unassignedID });
      } catch (e) {
        console.log(e);
      }
    }
  }

  createServiceIssue = async () => {
    // getting the 'unassigned' values
    const {
      statusId, categoryId, propertyId
    } = this.state;

    // getting due date, is always 'today' + 1 day
    const isoDate = new Date(`${new Date().toString().split('GMT')[0]} UTC`);
    isoDate.setDate(isoDate.getDate() + 1);
    const stringIsoDate = isoDate.toISOString().split('.')[0];

    const description = `Category: ${this.state.category}
    Message: ${this.state.message}`;

    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    const accNumber = _.get(this.props.user.attributes, 'custom:ext_ref_id');

    const requestBody: INewClientServiceIssueDefinition = {
      'function_type': 'create_ticket',
      'CategoryID': categoryId,
      'Description': description,
      'DueDate': stringIsoDate,
      'StatusID': statusId,
      'Title': 'Anequim support request',
      'LoggedUserEmail': loggedUserEmail,
      'PropertyId': Number(propertyId),
      'USAccId': accNumber
    };
    const ck = Utils.readCookie();
    const requestOptions = {
      'method': 'POST',
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': ck
      },
      'body': JSON.stringify(requestBody)
    };

    try {
      // Send the request to create a new service issue and link tenant and property
      await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}zcrm/create-ticket`, requestOptions)
        .then((r) => r.json())
        .then((data) => {
          this.handleClose();
          this.setState({ 'feedbackModalShowing': true, 'createdServiceIssue': data });
        });
    } catch (e) {
      console.log(e);
    }
  };

  private getAdvocateId() {
    return null;
  }

  // eslint-disable-next-line max-len
  private categoryHandler(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, selectCategories) {
    const catId = e.target.value;
    const cat = _.find(selectCategories, { 'id': catId });
    if (cat.propertyId === '59') {
      const advocateId = this.getAdvocateId();
      if (advocateId !== null) {
        this.setState({
          'category': cat.category,
          'propertyId': cat.propertyId
        });
      } else {
        this.setState({
          'category': cat.category,
          'propertyId': cat.propertyId
        });
      }
    } else {
      this.setState({
        'category': cat.category,
        'propertyId': cat.propertyId
      });
    }
  }

  handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      await this.createServiceIssue();
    } else {
      // eslint-disable-next-line no-alert
      alert('Todos los campos son requeridos');
    }
  };

  render = () => {
    const selectCategories: Array<any> = [
      { 'id': '1', 'category': 'Remote professional question', 'propertyId': '1' },
      { 'id': '2', 'category': 'Invoices/Financial question', 'propertyId': '1' },
      { 'id': '3', 'category': 'Portal/Platform question', 'propertyId': '1' },
      { 'id': '4', 'category': 'Add a new remote professional', 'propertyId': '1' },
      { 'id': '5', 'category': 'Other', 'propertyId': '1' }
    ];

    const { createdServiceIssue, dataLoadingError } = this.state;
    if (dataLoadingError) {
      return (
        <Modal animation={false} show={this.state.modalShowing} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Ha sucedido un error, por favor intenta de nuevo más tarde.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <div>
        <Button variant="primary crear-ticket-button" onClick={this.handleShow}>
          Create ticket
        </Button>

        {/* eslint-disable-next-line max-len */}
        <Modal className="modal-design" animation={false} show={this.state.modalShowing} onHide={this.handleClose}
               centered>
          <Modal.Header>
            <Container className="d-flex flex-column">
              <Row className="justify-content-center soporte-title">
                <Col><Modal.Title className="support-ticket-title">Support ticket</Modal.Title></Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="form-group-left-aligned">
              <Form.Label className="category-message-headers">Category: </Form.Label>
              <Form.Control className="category-select" as="select" required onChange={
                (e) => {
                  this.categoryHandler(e, selectCategories);
                }
              }>
                <option>Select category</option>
                {/* eslint-disable-next-line max-len */}
                {selectCategories.map((value) => <option key={value.id} value={value.id}>{value.category}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group className="form-group-left-aligned">
              <Form.Label className="category-message-headers">Message: </Form.Label>
              <Form.Control className="message-textarea" type="text" as="textarea" rows={3}
                            onChange={(e) => this.setState({ 'message': e.target.value })}
                            value={this.state.message}
                            placeholder="Type your message here"
                            maxLength={350}
                            required/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button className="cancel-button" onClick={this.handleClose} style={{ 'marginRight': '10px' }}>
                Cancel
              </Button>
              <Button className="send-button" onClick={this.handleSubmit}>
                Send
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal animation={false}
               show={this.state.feedbackModalShowing}
               onHide={this.handleFeedbackModalClose}
               backdrop="static"
               keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>Support request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your request has been created
            successfully {createdServiceIssue && `with the ticket ID: ${createdServiceIssue.ServiceManagerIssueID}, `}
            our team will be in contact shortly
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleFeedbackModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
}
