// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as _ from 'lodash';
import { CenteredSpinner } from '../components/centered_spinner';
import '../assets/styles/service-issues.scss';
import { ClientServiceIssuesButtonSection } from '../components/ClientServiceIssuesButtonSection';
import { Environment } from '../environment';
import { IClientProfileModel } from '../components/interfaces/IClientProfileModel';
import { Utils } from '../utils/utils';

type Props = {
  user: any
}

export const ClientServiceIssues: React.FC<Props> = ({ user }) => {
  const [clientProfile, setClientProfile] = useState({} as IClientProfileModel);
  const [error, setError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const loadClientProfile = async () => {
    try {
      const userName = _.get(user, 'username');
      const userAccountId = _.get(user.attributes, 'custom:ext_ref_id');
      const payload = {
        'name': userName,
        'function_type': 'client_by_reference',
        'usAccountId': userAccountId
      };
      const ck = Utils.readCookie();
      const response = await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}zcrm/client-by-reference`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': ck
        },
        'body': JSON.stringify(payload)
      });
      const data = await response.json();
      setClientProfile(data);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };

  useEffect(() => {
    setShowSpinner(true);
    loadClientProfile();
    setShowSpinner(false);
  }, []);

  let content;

  if (showSpinner) {
    content = (<CenteredSpinner/>);
  } else if (error) {
    content = (
      <div>
        <Container>
          <h5>There has been an unexpected error, please try again later</h5>
        </Container>
      </div>
    );
  } else {
    content = (
      <div>
        <Container fluid id="service-issues-wrapper">
          <Row className="justify-content-center">
            <Col sm={8} className="text-center">
              <div className="help-center-container">
                <div className="comment-button-label">
                  <h3 className="si-header">Help center</h3>
                  <p className="si-body">Do you need help or have a question?<br/>Send us a ticket!</p>
                </div>
                <div id="si-comment-button-container">
                  {/* eslint-disable-next-line max-len */}
                  <ClientServiceIssuesButtonSection key={1} tileData={null} user={user} profileData={clientProfile}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (content);
};
