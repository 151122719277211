import { Environment } from '../environment';

export class ZohoHelper {
   accessToken = '';

   public static loginToZoho = (username: string, password: string, ck: string): Promise<Response> => fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}zcrm/login`, {
     'method': 'POST',
     'headers': {
       'Content-Type': 'application/json',
       'Authorization': ck
     },
     'body': JSON.stringify({
       'username': username,
       'password': password,
       'function_type': 'login'
     })
   });
}
